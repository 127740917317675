<template>
  <v-app>
    <v-app-bar
      app
      style="z-index: 999;"
    >
      <router-link to="/">
        <v-toolbar-title
          to="/"
          class="headline text-uppercase"
          style="cursor: pointer;"
        >
          <span>Obozy</span>
          <span class="font-weight-light">Relacje</span>
        </v-toolbar-title>
      </router-link>
      <v-switch
        v-model="multiDrag"
        label="multi-przeciąganie (eksperymentalne!)"
        hide-details
        class="ml-10"
      />
      <v-spacer />
      <!-- <v-btn
        v-if="user"
        text
        to="/edit"
      >
        <span class="mr-2">Edycja Obozów</span>
        <v-icon>edit</v-icon>
      </v-btn> -->
      <v-btn
        v-if="user"
        text
        @click="logout"
      >
        <span class="mr-2">Wyloguj</span>
        <v-icon>open_in_new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      //
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    multiDrag: {
      get () { return this.$store.state.multiDrag },
      set (value) { this.$store.commit('setMultiDrag', value) }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
