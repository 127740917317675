import Vue from 'vue'
import Vuetify, { VLayout } from 'vuetify/lib'
import pl from 'vuetify/es5/locale/pl'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#862990',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  components: { VLayout },
  lang: {
    locales: { pl },
    current: 'pl'
  },
  icons: {
    iconfont: 'md'
  }
})
