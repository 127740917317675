import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'

import axios from 'axios'

const baseURL = 'https://obozyrelacja.musicandmore.pl' // 'http://localhost:4000/'

const http = axios.create({
  baseURL,
  timeout: 360000,
  headers: { 'Content-Type': 'application/json' }
})

let firstRequest = true

function getToken () {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.auth().currentUser.getIdToken(firstRequest).then((idToken) => {
          firstRequest = false
          resolve(idToken)
        }).catch((error) => {
          console.log(error)
          reject(error)
        })
      } else {
        console.log('User is undefinded')
        reject(new Error('User is undefinded'))
      }
    })
  })
}

http.interceptors.request.use(
  (config) => {
    return getToken().then((idToken) => {
      config.headers.Authorization = `Bearer ${idToken}`
      return Promise.resolve(config)
    }).catch((error) => Promise.reject(error))
  },
  (error) => Promise.reject(error)
)

Vue.prototype.$http = http
