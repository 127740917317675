<template>
  <AddDay />
</template>

<script>
import AddDay from '../components/AddDay'

export default {
  components: {
    AddDay
  }
}
</script>
