import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'

import './plugins'

import router from './router'
import store from '@/store'

import VueLazyload from 'vue-lazyload'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  attempt: 5,
  throttleWait: 1000,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.5
  }
})

new Vue({
  router,
  store,
  vuetify,
  created () {
    firebase.initializeApp({
      apiKey: 'AIzaSyCw-xVgTN6ov2VCXClg0dFvlVIgHBC9NWc',
      authDomain: 'obozy-relacja.firebaseapp.com',
      databaseURL: 'https://obozy-relacja.firebaseio.com',
      projectId: 'obozy-relacja',
      storageBucket: 'obozy-relacja.appspot.com',
      messagingSenderId: '846984538669',
      appId: '1:846984538669:web:0ef7b16b598b0e6b'
    })
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('autoSignIn', user)
      } else {
        router.push({ name: 'login' })
      }
    })
  },
  render: h => h(App)
}).$mount('#app')
