<template>
  <v-container fluid>
    <v-layout
      wrap
    >
      <v-flex
        xs12
      >
        <div
          style="height: 800px; overflow-y: scroll; overflow-x: hidden;"
        >
          <draggable
            :key="`draggable-${multiDrag}`"
            :scroll-sensitivity="200"
            :force-fallback="true"
            :list="items"
            :group="{ name: 'normalDay' }"
            class="row wrap"
            :multi-drag="multiDrag"
            multi-drag-key="ctrl"
            :style="`background: ${notSaved ? '#f1ea93' : '#aff193;'}`"
            @change="notSaved = true"
          >
            <v-flex
              v-for="(item, index) in items"
              :key="item.href"
              shrink
              class="my-2 mx-5"
              style="width: 300px; height: 300px;"
            >
              <ImageView
                :key="item.poster"
                v-model="item.title"
                :name="item.name"
                :image-url="item.poster"
                @input="notSaved = true"
                @deleteItem="deleteItem(index)"
              />
            </v-flex>
          </draggable>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ImageView from '../components/ImageView'
import draggable from '../plugins/vuedraggable/vuedraggable'
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  components: {
    draggable,
    ImageView
  },
  props: {
    camp: {
      type: String,
      required: true
    },
    batch: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      notSaved: false
    }
  },
  computed: {
    multiDrag () {
      return this.$store.getters.multiDrag
    }
  },
  watch: {
    camp () {
      this.fetch()
    },
    batch () {
      this.fetch()
    },
    day () {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  beforeDestroy () {
    firebase.database().ref(`day/${this.camp}/${this.batch};${this.day}`).off()
  },
  methods: {
    fetch () {
      this.$http.get(`/days/${this.camp}/${this.batch}/${this.day}`).then((res) => {
        if (res.data && res.data.media && res.data.media.length) this.items = res.data.media
        else this.items = []
      }).catch((e) => {
        console.error(e)
      })
      let firstTime = true
      firebase.database().ref(`day/${this.camp}/${this.batch};${this.day}`).off()
      firebase.database().ref(`day/${this.camp}/${this.batch};${this.day}`).on('value', (snapshot) => {
        if (firstTime) {
          firstTime = false
        } else {
          this.fetch()
        }
      })
      this.notSaved = false
    },
    saveDay () {
      this.$http.put(`/days/${this.camp}/${this.batch}/${this.day}`, this.items).then((res) => {
      }).catch((e) => {
        console.error(e)
      })
    },
    deleteItem (index) {
      this.items.splice(index, 1)
    }
  }
}
</script>

<style scoped>

.sortable-selected >>> .v-image__image {
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
}

.sortable-selected {
  box-shadow: rgba(3, 119, 214, 0.8) 0px 0px 0px 4px;
}

</style>
