<template>
  <v-layout>
    <v-flex
      xs3
      offset-xs5
    >
      <v-list two-line>
        <template v-for="(item, index) in days">
          <v-list-tile
            :key="item.title"
            @click="() => { DialogEditDay(index) }"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.date }}</v-list-tile-title>
              <v-list-tile-sub-title class="text--primary">
                {{ item.camp }}
              </v-list-tile-sub-title>
              <v-list-tile-sub-title>{{ item.batch }}</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-list-tile-action-text>{{ toWarsawTimeZone(item.createdDate) }}</v-list-tile-action-text>
              <v-btn
                v-if="index !== 0"
                style="height: 20px; width: 20px;"
                fab
                small
                color="primary"
                @click.stop="dayMoveUp(index)"
              >
                <v-icon dark>
                  keyboard_arrow_up
                </v-icon>
              </v-btn>
              <v-btn
                v-if="index !== (days.length - 1)"
                style="height: 20px; width: 20px;"
                fab
                small
                color="primary"
                @click.stop="dayMoveDown(index)"
              >
                <v-icon dark>
                  keyboard_arrow_down
                </v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>

          <v-layout
            :key="item.title"
            row
            wrap
            style="cursor: pointer;"
            @click="() => { DialogEditDay(index) }"
          >
            <div
              v-for="(medium, indexMedia) in item.media"
              :key="indexMedia + 'image'"
              v-lazy-container="{ selector: 'img' }"
            >
              <img
                :data-src="medium.poster"
                style="height: 50px; width: 50px; float: left;"
              >
              <!-- <v-img
                :src="medium.poster"
                :lazy-src="medium.poster"
                aspect-ratio="1"
                height="100"
                class="grey lighten-2"
              /> -->
            </div>
          </v-layout>

          <v-divider
            v-if="index + 1 < days.length"
            :key="index"
            class="mb-2 mt-2"
          />
        </template>
      </v-list>
    </v-flex>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Edycja dnia
        </v-card-title>

        <v-card-text>
          <v-date-picker
            v-model="date"
            full-width
            landscape
            :first-day-of-week="1"
            locale="pl-pl"
            min="2019-06-22"
            max="2019-08-25"
          />
          <v-select
            v-model="camp"
            class="mt-3"
            :items="camps"
            label="Camp"
          />
          <v-select
            v-model="batch"
            :items="campsWithBatches[camp]"
            label="Turnus"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            color="error"
            @click="() => { dialog = false; deleteDay(dayToEdit) }"
          >
            Usuń dzień
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            @click="() => { dialog = false; editDay(dayToEdit) }"
          >
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment-timezone'

export default {
  data () {
    return {
      days: [],
      dayToEdit: null,
      dialog: false,
      camp: '',
      batch: '',
      date: '',
      campsWithBatches: {
        Rafa: [
          'turnus 1: od 4 lipca do 14 lipca (11 dni)',
          'turnus 2: od 14 lipca do 25 lipca (12 dni)',
          'turnus 3: od 25 lipca do 4 sierpnia (11 dni)',
          'turnus 4: od 4 sierpnia do 16 sierpnia (13 dni)',
          'turnus 5: od 16 sierpnia do 25 sierpnia (10 dni)'
        ],
        Mierzeja: [
          'turnus 1: od 11 sierpnia do 21 sierpnia (10 dni)'
        ],
        Bęsia: [
          'turnus 1: od 23 czerwca do 3 lipca (11 dni)'
        ],
        Zakopiec: [
          'turnus alfa: od 22 czerwca do 1 lipca (10 dni)',
          'turnus 0: od 1 lipca do 10 lipca (10 dni)',
          'turnus 1: od 10 lipca do 19 lipca (10 dni)',
          'turnus 2: od 19 lipca do 29 lipca (11 dni)'
        ]
      },
      camps: [
        'Rafa',
        'Mierzeja',
        'Bęsia',
        'Zakopiec'
      ]
    }
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  created () {
    this.fetchDays()
  },
  methods: {
    fetchDays () {
      this.$http.get('/days')
        .then((res) => {
          this.days = res.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    deleteDay (index) {
      const dayId = this.days[index].id
      this.$http.delete(`/days/${dayId}`)
        .then((res) => {
          this.days.splice(index, 1)
          console.log(res)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    editDay (index) {
      const dayId = this.days[index].id
      this.$http.put(`/days/${dayId}`, {
        date: this.date,
        camp: this.camp,
        batch: this.batch
      }).then((res) => {
        this.days[index].date = this.date
        this.days[index].camp = this.camp
        this.days[index].batch = this.batch
      }).catch((e) => {
        console.log(e)
      })
    },
    DialogEditDay (index) {
      this.dayToEdit = index
      this.dialog = true
      this.date = this.days[index].date
      this.camp = this.days[index].camp
      this.batch = this.days[index].batch
    },
    toWarsawTimeZone (date) {
      return moment(date).tz('Europe/Warsaw').format('YYYY-MM-DD HH:mm')
    },
    dayMoveDown (index) {
      const day = this.days[index]
      const dayNext = this.days[index + 1]
      this.days = this.moveElementInArray(this.days, index, index + 1)
      day.createdDate = moment(dayNext.createdDate).add(1, 'm').toDate()
      this.$http.put(`/days/${day.id}`, {
        createdDate: day.createdDate
      }).then((res) => {
      }).catch((e) => {
        console.log(e)
      })
    },
    dayMoveUp (index) {
      const day = this.days[index]
      const dayNext = this.days[index - 1]
      this.days = this.moveElementInArray(this.days, index, index - 1)
      day.createdDate = moment(dayNext.createdDate).add(-1, 'm').toDate()
      this.$http.put(`/days/${day.id}`, {
        createdDate: day.createdDate
      }).then((res) => {
      }).catch((e) => {
        console.log(e)
      })
    },
    moveElementInArray (array, fromIndex, toIndex) {
      if (fromIndex === toIndex) return array
      const newArray = [...array]
      const target = newArray[fromIndex]
      const inc = toIndex < fromIndex ? -1 : 1
      for (let i = fromIndex; i !== toIndex; i += inc) {
        newArray[i] = newArray[i + inc]
      }
      newArray[toIndex] = target
      return newArray
    }
  }
}
</script>
