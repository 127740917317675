<template>
  <v-card
    elevation="5"
    class="fileContainer pa-0"
  >
    <form
      v-if="isInitial || isSaving"
      enctype="multipart/form-data"
      novalidate
    >
      <div class="dropbox">
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving || disabled"
          accept="image/*"
          class="input-file"
          @change="changeEvent"
        >
        <p v-if="disabled">
          <br>Nie można dodać plików<br><br>
        </p>
        <p v-else-if="isInitial">
          <br>Przeciągnij zdjęcia<br>lub kliknij aby dodać
        </p>
        <p v-else-if="isSaving">
          <br>Kompresowanie<br>{{ filesLoaded }} / {{ fileCount }} plik(i/ów)...<br>
        </p>
      </div>
    </form>
    <div v-if="isSuccess">
      <h2>Pliki przesłane.</h2>
      <p>
        <a
          href="javascript:void(0)"
          @click="() => { reset() }"
        >Prześlij inny plik</a>
      </p>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Nie udało się przesłać plików.</h2>
      <p>
        <a
          href="javascript:void(0)"
          @click="() => { reset() }"
        >Spróbuj ponownie</a>
      </p>
      <pre>{{ uploadError }}</pre>
    </div>
  </v-card>
</template>

<script>

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files',
      fileCount: 0,
      filesLoaded: 0
    }
  },
  computed: {
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  mounted () {
    this.reset()
  },
  methods: {
    changeEvent (e) {
      this.filesChange(e.target.name, e.target.files)
    },
    reset () {
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
    },
    filesChange (fieldName, fileList) {
      if (!fileList.length) return
      this.fileCount = fileList.length
      this.currentStatus = STATUS_SAVING

      for (const file of fileList) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.downscaleImage(file.name, e.target.result, 969).then(result => {
            this.$emit('newFile', result)
            this.filesLoaded++
            if (this.filesLoaded === this.fileCount) {
              this.fileCount = 0
              this.filesLoaded = 0
              this.currentStatus = STATUS_INITIAL
            }
          })
        }
        reader.readAsDataURL(file)
      }

      this.uploadError = null
    },
    getImage (dataUrl) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = dataUrl
        image.onload = () => {
          resolve(image)
        }
        image.onerror = (el, err) => {
          reject(err.error)
        }
      })
    },
    base64ToArrayBuffer (base64) {
      base64 = base64.replace(/^data:([^;]+);base64,/gmi, '')
      var binaryString = atob(base64)
      var len = binaryString.length
      var bytes = new Uint8Array(len)
      for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }
      return bytes.buffer
    },
    getOrientation (dataUrl) {
      return new Promise((resolve, reject) => {
        const view = new DataView(this.base64ToArrayBuffer(dataUrl))
        if (view.getUint16(0, false) !== 0xFFD8) {
          return resolve(-2)
        }
        const byteLength = view.byteLength
        let offset = 2
        while (offset < byteLength) {
          if (view.getUint16(offset + 2, false) <= 8) return resolve(-1)
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
              return resolve(-1)
            }

            const little = view.getUint16(offset += 6, false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return resolve(view.getUint16(offset + (i * 12) + 8, little))
              }
            }
          } else if ((marker & 0xFF00) !== 0xFF00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return resolve(-1)
      })
    },
    async downscaleImage (name, dataUrl, maxHeight, imageType, imageArguments) {
      // Provide default values
      imageType = imageType || 'image/jpeg'
      imageArguments = imageArguments || 0.8
      const maxWidth = Infinity

      // Create a temporary image so that we can compute the height of the downscaled image.
      const image = await this.getImage(dataUrl)
      const srcOrientation = await this.getOrientation(dataUrl)
      const oldWidth = image.width
      const oldHeight = image.height
      const scale = (srcOrientation > 4
        ? Math.min(maxHeight / oldWidth, maxWidth / oldHeight, 1)
        : Math.min(maxWidth / oldWidth, maxHeight / oldHeight, 1))
      const newHeight = Math.round(oldHeight * scale)
      const newWidth = Math.round(oldWidth * scale)

      // Create a temporary canvas to draw the downscaled image on.
      const canvas = document.createElement('canvas')

      // if (srcOrientation > 4 && srcOrientation < 9) {
      //   canvas.width = newHeight
      //   canvas.height = newWidth
      // } else {
      canvas.width = newWidth
      canvas.height = newHeight
      // }

      // transform context before drawing image
      const ctx = canvas.getContext('2d')
      // switch (srcOrientation) {
      //   case 2: ctx.transform(-1, 0, 0, 1, newWidth, 0); break
      //   case 3: ctx.transform(-1, 0, 0, -1, newWidth, newHeight); break
      //   case 4: ctx.transform(1, 0, 0, -1, 0, newHeight); break
      //   case 5: ctx.transform(0, 1, 1, 0, 0, 0); break
      //   case 6: break
      //   case 7: ctx.transform(0, -1, -1, 0, newHeight, newWidth); break
      //   case 8: ctx.transform(0, -1, 1, 0, 0, newWidth); break
      //   default: break
      // }

      // Draw the downscaled image on the canvas and return the new data URL.
      ctx.drawImage(image, 0, 0, newWidth, newHeight)
      const newDataUrl = canvas.toDataURL(imageType, imageArguments)
      return {
        name: name,
        data: newDataUrl
      }
    }
  }
}
</script>

<style c>
.fileContainer {
  min-height: 133px;
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: white;
  color: rgb(71, 71, 71);
  padding: 10px 10px;
  min-height: 100px;
  position: relative;
  cursor: pointer;
}

.input-file {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightcyan;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 10px 0;
}
</style>
