<template>
  <v-img
    class="card-grid"
    :src="imageUrl"
  >
    <div class="contrast-text">
      <v-icon
        style="vertical-align: bottom;"
        color="pink lighten-2"
        class="py-1"
        size="30"
        tabindex="-1"
        @click="deleteItem"
      >
        delete_forever
      </v-icon>
      <span
        style="vertical-align: super;"
        class="text-truncate mx-2"
      >{{ name }}</span>
    </div>
    <v-text-field
      :value="value"
      style="position: absolute; bottom: 20px; width: 280px; margin: 0 10px;"
      class="text-field-description"
      label="Tytuł zdjęcia"
      solo
      light
      hide-details
      @click.prevent="() => {}"
      @input="$emit('input', $event)"
    />
  </v-img>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    deleteItem () {
      if (confirm('Czy na pewno chcesz usunąć?')) {
        this.$emit('deleteItem')
      }
    }
  }
}
</script>

<style scoped>

.card-grid {
  position: relative;
  height: 300px;
  width: 300px;
}

.contrast-text {
  color: white;
  background-color: rgba(0, 0, 0, 0.329);
}

</style>
