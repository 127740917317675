<template>
  <v-container fluid>
    <v-layout
      wrap
    >
      <v-flex
        xs12
      >
        <div
          :style="`${items.length ? 'height: 800px;' : 'height: 10px;'} overflow-y: scroll; overflow-x: hidden;`"
        >
          <draggable
            :scroll-sensitivity="200"
            :force-fallback="true"
            :list="items"
            :group="{ name: 'draftDay' }"
            class="row wrap"
            style="background: #c7c1c1;"
            @change="saveDraftThrottle"
          >
            <v-flex
              v-for="(item, index) in items"
              :key="item.href"
              shrink
              class="my-2 mx-5"
              style="width: 300px; height: 300px;"
            >
              <ImageView
                :key="item.poster"
                v-model="item.title"
                :name="item.name"
                :image-url="item.poster"
                @input="saveDraftThrottle"
                @deleteItem="deleteItem(index)"
              />
            </v-flex>
          </draggable>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'
import ImageView from '../components/ImageView'
import draggable from 'vuedraggable'
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  components: {
    draggable,
    ImageView
  },
  props: {
    camp: {
      type: String,
      required: true
    },
    batch: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      saveDraftThrottle: _.throttle(() => {
        this.saveDraft()
      }, 2000)
    }
  },
  watch: {
    camp () {
      this.fetch()
    },
    batch () {
      this.fetch()
    },
    day () {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  destroyed () {
    firebase.database().ref(`/drafts/${this.camp}/${this.batch};${this.day}/`).off()
  },
  methods: {
    fetch () {
      firebase.database().ref(`/drafts/${this.camp}/${this.batch};${this.day}/`).orderByChild('index').on('value', (snapshot) => {
        const array = []
        snapshot.forEach((child) => {
          array.push({
            key: child.key,
            title: '',
            ...child.val()
          })
        })
        this.items = array
      })
    },
    deleteItem (index) {
      firebase.database().ref(`/drafts/${this.camp}/${this.batch};${this.day}/${this.items[index].key}`).set(null)
    },
    saveDraft () {
      const object = {}
      for (const [index, item] of this.items.entries()) {
        const { key, ...itemWithoutKey } = item
        itemWithoutKey.index = index
        object[key] = itemWithoutKey
      }
      firebase.database().ref(`/drafts/${this.camp}/${this.batch};${this.day}/`).set(object)
    }
  }
}
</script>

<style scoped>

</style>
