<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm8
      md4
    >
      <v-card class="elevation-18">
        <v-toolbar
          dark
          color="primary"
        >
          <transition name="fade">
            <v-toolbar-title>Logowanie</v-toolbar-title>
          </transition>
          <v-spacer />
        </v-toolbar>
        <v-form @submit.prevent="onSignin">
          <v-card-text>
            <transition-group name="fade">
              <v-text-field
                :key="'email'"
                v-model="email"
                prepend-icon="email"
                name="email"
                label="Email"
                type="text"
                autocomplete="email"
              />
              <v-text-field
                :key="'password'"
                v-model="password"
                prepend-icon="lock"
                name="password"
                label="Hasło"
                type="password"
                autocomplete="current-password"
              />
            </transition-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <transition name="fade">
              <v-btn
                color="primary"
                type="submit"
                :disabled="loading"
                :loading="loading"
              >
                Zaloguj
              </v-btn>
            </transition>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Login',
  props: {
  },
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    user () {
      return this.$store.getters.user
    }
  },
  watch: {
    user (newVal) {
      if (newVal) {
        this.$router.replace({ name: 'home' })
      }
    }
  },
  methods: {
    onSignin () {
      this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
    }
  }
}
</script>

<style scoped>
</style>
