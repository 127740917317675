import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  state: {
    user: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    signUserIn ({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then(user => {
        const newUser = {
          id: user.uid,
          email: user.email,
          name: user.displayName
        }
        commit('setUser', newUser)
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
          sessionStorage.setItem('idToken', idToken)
        }).catch((error) => {
          console.log(error)
        })
        commit('setLoading', false)
      }).catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        console.log(error)
      })
    },
    autoSignIn ({ commit }, payload) {
      commit('setUser', {
        id: payload.uid,
        email: payload.email,
        name: payload.displayName
      })
    },
    logout ({ commit }) {
      firebase.auth().signOut()
      commit('setUser', null)
    }
  },
  getters: {
    user (state) {
      return state.user
    }
  }
}
