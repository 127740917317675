export default {
  state: {
    loading: false,
    error: null,
    multiDrag: false
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
    setMultiDrag (state, payload) {
      state.multiDrag = payload
    }
  },
  actions: {
    clearError ({ commit }) {
      commit('clearError')
    }
  },
  getters: {
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
    multiDrag (state) {
      return state.multiDrag
    }
  }
}
