<template>
  <v-container fluid>
    <v-layout
      wrap
    >
      <v-flex xs12>
        <v-layout wrap>
          <v-flex
            xs10
            lg5
          >
            <UploadFiles
              class="mb-4"
              :disabled="!batch"
              @newFile="addFiles"
            />
          </v-flex>
          <v-flex
            xs2
            lg1
          >
            <v-card
              elevation="5"
              class="mx-2 mt-1 youtube-card"
              @click.stop="youtubeDialog = true"
            >
              <v-img
                height="129"
                src="/yotutbe.jpg"
              >
                <div
                  class="headline"
                  style="background-color: #c4302b; margin: 0; position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%);"
                >
                  DODAJ
                </div>
              </v-img>
            </v-card>
          </v-flex>
          <v-flex
            xs6
            class="px-4"
          >
            <v-layout
              wrap
              align-center
              fill-height
            >
              <v-flex xs4>
                <v-menu
                  v-model="calendar"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Data relacji"
                      prepend-icon="event"
                      readonly
                      hide-details
                      class="mr-4"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    :first-day-of-week="1"
                    locale="pl-pl"
                    @input="calendar = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="camp"
                  :items="camps"
                  hide-details
                  label="Camp"
                  class="mr-2"
                  no-data-text="Wybierz datę"
                />
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="batch"
                  :disabled="!camp"
                  :items="batches"
                  hide-details
                  label="Turnus"
                  class="ml-2"
                />
              </v-flex>
              <v-flex xs12>
                <v-layout>
                  <!-- <v-flex shrink>
                    <v-btn
                      color="grey"
                      class="mx-2"
                      :disabled="!batch || loading"
                      @click="saveDraft"
                    >
                      Zapisz Draft
                    </v-btn>
                  </v-flex> -->
                  <v-flex shrink>
                    <v-btn
                      color="success"
                      class="mx-2"
                      :disabled="!batch || loading"
                      @click="publishDraft"
                    >
                      Opublikuj Draft
                    </v-btn>
                  </v-flex>
                  <v-spacer />
                  <v-flex shrink>
                    <v-btn
                      color="primary"
                      class="mx-2"
                      :disabled="!batch || loading"
                      @click="postDay"
                    >
                      Zmień Opublikowaną Relację
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear
              :value="(filesSent / filesToSent) * 100"
              height="25"
              class="mb-0"
            >
              <template>
                <strong>{{ `${filesSent} / ${filesToSent}` }}</strong>
              </template>
            </v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <DraftDay
              v-if="camp && batch && date"
              ref="DraftDay"
              :camp="camp"
              :batch="batch"
              :day="date"
            />
          </v-flex>
          <v-flex xs12>
            <NormalDay
              v-if="camp && batch && date"
              ref="NormalDay"
              :camp="camp"
              :batch="batch"
              :day="date"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="youtubeDialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Dodaj filmik z YouTube
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="youtubeTitle"
            label="Tytuł"
            hide-details
          />
          <v-text-field
            v-model="youtubeLink"
            label="Link lub ID"
            hide-details
            class="mb-4"
          />
          <v-img
            contain
            height="150"
            :src="youtubeLink ? `https://i3.ytimg.com/vi/${youtubeLink}/hqdefault.jpg` : '/hqdefault.jpg'"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!youtubeLink"
            @click="addVideo"
          >
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Trwa wysyłanie Relacji
          <v-progress-linear
            :indeterminate="true"
            color="white"
            class="mb-0"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UploadFiles from '../components/UploadFiles'
import DraftDay from '../components/DraftDay'
import NormalDay from '../components/NormalDay'

export default {
  components: {
    DraftDay,
    NormalDay,
    UploadFiles
  },
  data () {
    return {
      date: (new Date()) > (new Date('2019-06-22')) ? new Date().toISOString().substr(0, 10) : null,
      camp: null,
      batch: null,
      filesArray: [],
      calendar: false,
      campsWithBatches: {},
      youtubeDialog: false,
      youtubeTitle: '',
      youtubeLink: '',
      loading: false,
      filesToSent: 0,
      filesSent: 0
    }
  },
  computed: {
    camps () {
      return Object.keys(this.campsWithBatches)
    },
    batches () {
      return this.camp ? this.campsWithBatches[this.camp] : []
    }
  },
  watch: {
    youtubeLink (newVal) {
      if (!newVal) return
      this.youtubeLink = (newVal + '').replace(/.*?=/g, '')
    },
    camp (newVal, oldVal) {
      if (newVal !== oldVal) this.batch = null
    },
    date: {
      handler (newVal) {
        this.$http.get(`/camp/date/${newVal}`).then((res) => {
          this.campsWithBatches = res.data
          if (!Object.keys(this.campsWithBatches).includes(this.camp)) {
            this.camp = null
            this.batch = null
            return
          }
          if (!this.campsWithBatches[this.camp].includes(this.batch)) this.batch = null
        })
      },
      immediate: true
    }
  },
  methods: {
    addFiles (file) {
      this.filesToSent += 1
      this.$http.post(`/days/draft/${this.camp}/${this.batch}/${this.date}`, file).then(() => {
        this.filesSent += 1
      }).catch(() => {
        this.filesSent -= 1
      })
    },
    addVideo () {
      this.filesArray.push({
        name: this.youtubeLink,
        data: `https://i3.ytimg.com/vi/${this.youtubeLink}/hqdefault.jpg`,
        title: this.youtubeTitle
      })
      this.youtubeLink = ''
      this.youtubeTitle = ''
      this.youtubeDialog = false
    },
    saveDraft () {
      this.$refs.DraftDay.saveDraft()
    },
    publishDraft () {
      this.loading = true
      this.$http.put(`/days/draft/${this.camp}/${this.batch}/${this.date}`).then(() => {
        this.$refs.NormalDay.fetch()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    postDay () {
      this.$refs.NormalDay.saveDay()
    }
  }
}
</script>

<style>

</style>
